import { CountrySelectEvent } from "../../events/AddressEvents";
import { getElement } from "../../helpers/htmlUtils";
import customersEnumsService from "../../services/customers/enumsService";
import { updateRequiredWhen } from "../forms";
import {
  createSelectComponent,
  getSelectCurrentSelection,
  setSelectSelectedOption,
  unselectSelectedOptions
} from "./selectComponent";

/** @type {WeakMap<HTMLSelectElement, CountrySelect>} */
const instanceWeakMap = new WeakMap();

class CountrySelect {
  /**
   * @param {HTMLSelectElement | string} el
   */
  constructor(el) {
    this.nativeEl = getElement(el);
    this.formEl = this.nativeEl.closest("form");
    this.stateContainerEl = this.formEl.querySelector(".js-state-id");
    this.stateIdEl = this.stateContainerEl?.querySelector("[name=stateId]");
    this.tailSelect = null;
    this.stateId = null;
    instanceWeakMap.set(this.nativeEl, this);
  }

  get value() {
    return this.nativeEl.value;
  }

  set value(value) {
    setSelectSelectedOption(this.nativeEl, value, false);
    this.toggleStateInput();
  }

  /** @type {import("../../services/customers/enumsService").CountryAPIModel | undefined} */
  get data() {
    return getSelectCurrentSelection(this.nativeEl);
  }

  setCountry(countryId) {
    setSelectSelectedOption(this.nativeEl, countryId, true);
    return this;
  }

  setState(stateId) {
    this.stateId = stateId;
    setSelectSelectedOption(this.stateIdEl, stateId, false);
    return this;
  }

  /** @type {import("../../services/customers/enumsService").StateAPIModel | undefined} */
  getState() {
    return getSelectCurrentSelection(this.stateIdEl);
  }

  /** @param {import("../../services/customers/enumsService").CountryAPIModel[]} countries */
  async init(countries, defaultValue) {
    this.tailSelect = createSelectComponent({
      select: this.nativeEl,
      data: countries,
      placeholder: "Select a country",
      defaultValue,
      labelKey: "name",
      valueKey: "countryId",
      showPlaceholderAsOption: true,
      options: {
        search: true,
        openAbove: true
      },
      getAttrs: (country) => {
        return `data-country-code=${country.code}`;
      },
      onChange: (event) => {
        CountrySelectEvent.dispatch(this.nativeEl, event);
        this.toggleStateInput();
      }
    });
  }

  toggleStateInput() {
    if (!this.stateContainerEl) {
      console.info("State is not configured for this element", this.nativeEl);
      return;
    }

    updateRequiredWhen(this.formEl, this.nativeEl);
    const isUSCountrySelected = this.data?.code === "US";
    // Hide when US country is selected
    this.stateContainerEl.classList.toggle("d-none", !isUSCountrySelected);

    if (isUSCountrySelected) {
      this.initializeStateDropdown();
    } else {
      this.stateId = null;
      unselectSelectedOptions(this.stateIdEl);
    }
  }

  async initializeStateDropdown() {
    await CountrySelect.initStateDropdown(
      this.stateIdEl,
      this.stateId ? Number(this.stateId) : ""
    );
  }

  /** @type {import("../../services/customers/enumsService").CountryAPIModel[]} */
  static countries = [];

  /** @type {import("../../services/customers/enumsService").StateAPIModel[]} */
  static states = [];

  /**
   * @param {HTMLSelectElement} select
   * @param {string} [defaultValue]
   */
  static async initStateDropdown(select, defaultValue) {
    this.states = await customersEnumsService.list("states");
    return createSelectComponent({
      select,
      defaultValue,
      data: this.states,
      placeholder: "Select a state",
      getAttrs: (state) => {
        return `data-state-code=${state.stateShortName}`;
      },
      getLabel: ({ stateName, stateShortName }) =>
        `${stateName} (${stateShortName})`,
      valueKey: "stateId",
      showPlaceholderAsOption: true,
      options: {
        search: true,
        openAbove: true
      }
    });
  }

  /**
   * @param {HTMLSelectElement | string} el
   * @param {string} [defaultValue]
   */
  static create = async (el, defaultValue) => {
    /** @type {import("../../services/customers/enumsService").CountryAPIModel[]} */
    this.countries = await customersEnumsService.countries();

    const countrySelect = new CountrySelect(el);
    await countrySelect.init(this.countries, defaultValue);
    return countrySelect;
  };

  /**
   * @param {NodeList | string} els The elements to initialize with country select.
   * @param {string} [defaultValue]
   */
  static createMany = async (els, defaultValue) => {
    let finalEls = els;
    if (typeof els === "string") finalEls = document.querySelectorAll(els);
    return Promise.all(
      Array.from(finalEls).map((el) => this.create(el, defaultValue))
    );
  };

  /**
   * Get the CountrySelect instance by the select element.
   * @param {HTMLSelectElement | string} el
   */
  static getBySelect(el) {
    return instanceWeakMap.get(getElement(el));
  }
}

export default CountrySelect;
