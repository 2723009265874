/**
 * Utility functions around date formatting for displaying dates across the application
 */
import {
  concatDateTime,
  convertDateToUtc,
  convertUtcToZoned,
  extractMomentDate,
  removeTZ,
  validateDate,
  withNullOnError
} from "./dates";

const user = JSON.parse(sessionStorage.getItem("user"));
const userTimezone = user?.timezone?.zoneName || "Europe/London";

const PATTERNS = Object.freeze({
  default: {
    date: "DD/MM/YYYY",
    shortDate: "DD/MM",
    time: "HH:mm:ss",
    shortTime: "HH:mm"
  },
  america: {
    date: "MM/DD/YYYY",
    shortDate: "MM/DD",
    time: "hh:mm:ss A",
    shortTime: "hh:mm A"
  }
});

export const is24HourTime = () => {
  return userTimezone.includes("Europe/");
};

// Pattern helpers
const getPattern = (timeZone = userTimezone) => {
  if (timeZone.includes("America/")) return PATTERNS.america;
  return PATTERNS.default;
};

export const getDatePattern = (timeZone = userTimezone) => {
  return getPattern(timeZone).date;
};

export const getTimePattern = (timeZone = userTimezone) => {
  return getPattern(timeZone).time;
};

export const getTimeOnlyPattern = (timeZone = userTimezone) => {
  return getPattern(timeZone).shortTime;
};

export const getDateTimePattern = (timeZone = userTimezone) => {
  return [getDatePattern(timeZone), getTimePattern(timeZone)].join(" ");
};

export const getDateTimeOnlyPattern = (timeZone = userTimezone) => {
  return [getDatePattern(timeZone), getTimeOnlyPattern(timeZone)].join(" ");
};

// Format helpers - using moment because of datepicker bound to it for selecting a date
export const formatDatePattern = withNullOnError((date, pattern) => {
  const dateObj = extractMomentDate(date);
  validateDate(dateObj);
  return moment(dateObj).format(pattern);
});

export const formatDate = (date, timeZone = userTimezone) => {
  return formatDatePattern(date, getDatePattern(timeZone));
};

export const formatTime = (date, timeZone = userTimezone) => {
  return formatDatePattern(date, getTimePattern(timeZone));
};

export const formatShortTime = (date, timeZone = userTimezone) => {
  const dateObj = typeof date === "string" ? parseDate(date, "HH:mm:ss") : date;
  return formatDatePattern(dateObj, getTimeOnlyPattern(timeZone));
};

export const formatDateTime = (date, timeZone = userTimezone) => {
  return formatDatePattern(date, getDateTimePattern(timeZone));
};

export const formatDateTimeOnly = (date, timeZone = userTimezone) => {
  return formatDatePattern(date, getDateTimeOnlyPattern(timeZone));
};

/**
 * Formats a date to ISO 8601 format in UTC.
 *
 * @param {Date | number} date The date to format
 * @returns {string | null} The date in an ISO 8601 format
 */
export const formatDateToISO = withNullOnError((date) => {
  const dateObj = extractMomentDate(date);
  validateDate(dateObj);
  return moment(dateObj).utc().format();
});

/**
 * Parses a valid date string to Date object in the local time.
 * The date's format can be provided to correctly parse the date.
 * When the format is omitted, the function will try to parse the
 * date as a date string that is supported by momentjs.
 *
 * @param {string | number | Date} date The date to parse
 * @param {string} pattern The date's pattern
 * @returns {Date | null} The date object
 */
export const parseDate = withNullOnError((date, pattern) => {
  const dateObj = moment(date, pattern).toDate();
  validateDate(dateObj);
  return dateObj;
});

export const parseUKDate = (date) => {
  if (!date) return null;
  return parseDate(date, "DD/MM/YYYY") || parseDate(date, "DD-MM-YYYY");
};

/**
 * @param {Date} date
 * @param {Intl.DateTimeFormatOptions} [options]
 * @returns
 */
export const dateToLocaleString = (date, options) => {
  const localeFromTimezone = /^Europe/.test(userTimezone) ? "en-GB" : "en-US";
  if (moment.isDate(date) || moment.isMoment(date))
    return date.toLocaleString(localeFromTimezone, options);

  return "-";
};

/**
 * Format a task's date time to time only.
 *
 * @param {string} date The date string in ISO representation
 * @param {string} time The time delimited by :, eg 10:20:59
 * @param {string} fromTimezone The timezone the date time tuple is provided from
 * @returns {string} The time formatted based on user's timezone preferences
 */
export const formatTaskTime = (date, time, fromTimezone) => {
  if (!fromTimezone || fromTimezone === userTimezone) return "";
  const utcDate = concatDateTime(date, time, fromTimezone);
  return (
    formatDatePattern(convertUtcToZoned(utcDate), getTimeOnlyPattern()) ?? ""
  );
};

/**
 * Format a task's date time to date only.
 *
 * @param {string} date The date string in ISO representation
 * @param {string} time The time delimited by :, eg 10:20:59
 * @param {string} fromTimezone The timezone the date time tuple is provided from
 * @returns {string} The date formatted based on user's timezone preferences
 */
export const formatTaskDate = (date, time, fromTimezone) => {
  if (!fromTimezone || fromTimezone === userTimezone) return "";
  const utcDate = concatDateTime(date, time, fromTimezone);
  return (
    formatDatePattern(convertUtcToZoned(utcDate), getPattern().shortDate) ?? ""
  );
};

/**
 * Formats a task's date time to date time ISO string in UTC.
 *
 * @param {string} date The date string in ISO representation
 * @param {string} fromTimezone Tge date formatted based on user's timezone preferences
 */
export const formatTaskDateTime = (date, fromTimezone) => {
  // Default to europe london if timezone is not available. This should never occur for new jobs
  const tz = fromTimezone || "Europe/London";
  return formatDateToISO(convertDateToUtc(removeTZ(date), tz));
};
