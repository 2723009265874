import "moment-timezone";
import forms, { shrinkLabels } from "./forms";
import { formatDate, getDatePattern } from "./date-formatters";

const helpers = {
  getCurrentTimeZone(callBack, zone = "Europe/London") {
    let url = `${
      server.serverJobBooking
    }get-time-zone?key=7LTPS5BL74K0&format=json&by=zone&zone=${encodeURIComponent(
      zone
    )}`;
    const xmlHttp = new XMLHttpRequest();
    xmlHttp.open("GET", url, false);
    xmlHttp.send(null);

    // store in session storage for next page load
    sessionStorage.setItem(
      "getCurrentTimeZone",
      JSON.stringify(xmlHttp.response)
    );
    callBack(JSON.parse(xmlHttp.response));
  },

  getLatLong(postcode) {
    if (postcode) {
      const xmlHttp = new XMLHttpRequest();

      xmlHttp.open(
        "GET",
        `https://api.postcodes.io/postcodes/${postcode}`,
        false
      );
      xmlHttp.send(null);

      const response = JSON.parse(xmlHttp.response);

      if (response.status == 200) {
        return [response.result.latitude, response.result.longitude];
      }

      return [];
    }

    return [];
  },

  getUrlParameter(name) {
    name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
    const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
    const results = regex.exec(location.search);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  },

  getCurrentPage() {
    return window.location.href.replace(/\/$/, "").split("/").pop();
  },

  getPage(page) {
    return page.split("/").pop();
  },

  stripHTML(html) {
    if (html && typeof html === "object") {
      return Object.keys(html).reduce((acc, key) => {
        acc[key] = this.stripHTML(html[key]);
        return acc;
      }, {});
    }

    const tmp = document.createElement("DIV");

    tmp.innerHTML = html;

    return tmp.textContent || tmp.innerText || "";
  },

  formatCurrency(value, customNegative) {
    if (value == null) {
      value = 0;
    }

    if (value || value == 0) {
      let isNegative = false;

      if (Math.sign(Number(value)) == "-1") {
        isNegative = true;
      }

      return `<span class="number-text ${
        isNegative || customNegative ? "negative" : ""
      }">${customNegative ? "-" : ""}£${Number(value).toLocaleString()}</span>`;
    }

    return "";
  },

  formatCurrencyValue(value, decimalPlaces = 2, formatting = "en-US") {
    return isNaN(value)
      ? ""
      : Number(value).toLocaleString(formatting, {
          maximumFractionDigits: decimalPlaces,
          minimumFractionDigits: decimalPlaces
        });
  },

  localStringToNumber(s) {
    const numberString = Number(String(s).replace(/[^0-9.-]+/g, ""));

    return numberString;
  },

  toLocaleCurrency({ value, settings }) {
    const self = this;

    for (const [key, val] of Object.entries(settings)) {
      if (!val) delete settings[key];
    }

    const options = {
      style: "currency",
      currency: "GBP",
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      ...settings
    };

    if (!isFinite(value)) {
      value = 0;
    }

    return !isNaN(value)
      ? self.localStringToNumber(value).toLocaleString(undefined, options)
      : "";
  },

  formatNumber(el) {
    const { type } = el.dataset;
    let { value } = el;

    function localStringToNumber(s) {
      return Number(String(s).replace(/[^0-9.-]+/g, ""));
    }

    const options = {
      style: type
    };

    if (type == "currency") {
      Object.assign(options, {
        currency: "GBP",
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
        currencyDisplay: "symbol"
      });
    }

    if (!isFinite(value)) {
      value = 0;
    }

    el.value = !isNaN(value)
      ? localStringToNumber(value).toLocaleString(undefined, options)
      : "";

    return el.value;
  },

  formatShortTime(value) {
    if (value) {
      let thisTime = value.split(":");

      let hour = thisTime[0];
      let min = thisTime[1];
      let newTime = hour + ":" + min;

      return newTime;
    }

    return "";
  },

  formatCxStopDateTimeUtc(stopDateTime, offset = 0) {
    const [date, time] = stopDateTime.split("T");
    const [hours, minutes, seconds] = time.replace("Z", "").split(":");

    let newDate = new Date(date);
    newDate.setUTCHours(hours, minutes, seconds - offset);

    let dateString = newDate.toISOString();
    dateString = dateString.replace(dateString.substr(-5), "Z");

    return dateString;
  },

  swapNodes(n1, n2) {
    const p1 = n1.parentNode;
    const p2 = n2.parentNode;
    let i1;
    let i2;

    if (!p1 || !p2 || p1.isEqualNode(n2) || p2.isEqualNode(n1)) {
      return;
    }

    for (const child of p1.children) {
      if (child.isEqualNode(n1)) {
        i1 = child;
      }
    }

    for (const child of p2.children) {
      if (child.isEqualNode(n2)) {
        i2 = child;
      }
    }

    if (p1.isEqualNode(p2) && i1 < i2) {
      i2++;
    }

    p1.insertBefore(n2, p1.children[i1]);
    p2.insertBefore(n1, p2.children[i2]);
  },

  thousandsDelimeter(num = 0) {
    if (num.toString().includes(".")) {
      let dec = "";
      const split = num.toString().split(".");
      num = split[0];
      dec = split[1];
      return `${num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.${dec}`;
    }
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },

  roundDecimal(n, l = 2) {
    return parseFloat(parseFloat(n).toFixed(l));
  },

  quarterHour(mins) {
    return (Math.round((+mins + 7.5) / 15) * 15) % 60;
  },

  random(length = 28) {
    const possible = range("A", "Z")
      .concat(range("a", "z"))
      .concat(range("0", "9"));

    const randomString = [];
    while (randomString.length < length) {
      const index = Math.floor(Math.random() * (possible.length - 1) + 1);
      randomString.push(possible[index]);
    }
    return randomString.join("");
  },

  range(start, stop) {
    const result = [];
    for (
      let index = start.charCodeAt(0), end = stop.charCodeAt(0);
      index <= end;
      ++index
    ) {
      result.push(String.fromCharCode(index));
    }
    return result;
  },

  caseForceTitle(str) {
    if (!str) {
      return null;
    }
    const strArr = str.toString().split(" ");
    const output = [];

    strArr.forEach((elem) => {
      if (elem.length === 1) {
        output.push(elem.toUpperCase());
      } else {
        const eachString =
          elem.charAt(0).toUpperCase() + elem.slice(1).toLowerCase();
        output.push(eachString);
      }
    });

    return output.join(" ");
  },

  caseCamelToTitle(str = "") {
    return this.caseForceTitle(str.replace(/([a-z0-9])([A-Z])/g, "$1 $2"));
  },

  caseSnakeToTitle(str) {
    const strArr = str.toString().split("_");
    const output = [];
    strArr.forEach((elem) => {
      const eachString =
        elem.charAt(0).toUpperCase() + elem.slice(1).toLowerCase();
      output.push(eachString);
    });
    return output.join(" ");
  },

  caseSnakeToCamel(str) {
    const strArr = str.toString().split("_");
    const output = [];
    strArr.forEach((elem) => {
      const eachString =
        elem.charAt(0).toUpperCase() + elem.slice(1).toLowerCase();
      output.push(eachString);
    });
    return output.join().charAt(0).toLowerCase();
  },

  caseTitleToSnake(screamingSnake = false) {
    const strArr = this.toString().split(" ");
    const output = [];
    strArr.forEach((elem) => {
      const eachString =
        screamingSnake === false ? elem.toLowerCase() : elem.toUpperCase();
      output.push(eachString);
    });
    return output.join("_");
  },

  titleCaseAddressFields(modal) {
    modal.querySelectorAll("input[name]").forEach((el) => {
      switch (el.name) {
        case "addressName":
        case "addressLine1":
        case "addressLine2":
        case "addressLine3":
        case "city":
        case "county":
          forms.insertValue(el, this.caseForceTitle(el.value));
          break;
        case "postCode":
          forms.insertValue(el, el.value.toUpperCase());
          break;
      }
    });
  },

  renderDatePickers(parent) {
    const dateFields = parent.querySelectorAll(".js-date-field");

    dateFields.forEach((el) => {
      const options = {
        locale: {
          format: getDatePattern()
        },
        linkedCalendars: false,
        autoUpdateInput: false,
        singleDatePicker: true,
        drops: "up"
      };

      jQuery(el).on("apply.daterangepicker", function (e, picker) {
        jQuery(this).val(formatDate(picker.startDate));

        // Shrink labels due to it not in the change state
        shrinkLabels();
      });

      jQuery(el).on("cancel.daterangepicker", function (e, picker) {
        jQuery(this).val("");
      });

      jQuery(el).daterangepicker(options);
    });
  },

  currencyFields(parent) {
    const currencyInputs = parent.querySelectorAll(
      'input[data-type="currency"]'
    );

    for (const currencyInput of currencyInputs) {
      if (currencyInput) {
        // bind event listeners
        currencyInput.addEventListener("focus", onFocus);
        currencyInput.addEventListener("blur", () => {
          onBlur(currencyInput, "currency");
        });
      }
    }

    function localStringToNumber(s, blur) {
      let numberString = Number(String(s).replace(/[^0-9.-]+/g, ""));

      if (!blur) {
        if (typeof numberString === "number") {
          if (numberString == 0) {
            numberString = "";
          }
        } else {
          numberString = "";
        }
      }

      return numberString;
    }

    function onFocus(e) {
      const { value } = e.target;
      e.target.value = value ? localStringToNumber(value) : "";
    }

    function onBlur(e, type) {
      const { value } = e;

      const options = {
        style: type
      };

      if (type == "currency") {
        Object.assign(options, {
          currency: "GBP",
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
          currencyDisplay: "symbol"
        });
      }

      e.value = !isNaN(value)
        ? localStringToNumber(value, true).toLocaleString(undefined, options)
        : localStringToNumber(0, true).toLocaleString(undefined, options);
    }
  },

  capitalizeFirstLetter(string) {
    if (!string) {
      return null;
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  },

  getStrictType(unknownType) {
    let jsType = Object.prototype.toString
      .call(unknownType)
      .replace(/[[\]']+/g, "")
      .split(" ");
    return jsType[1];
  },

  isValidDateObject(dateObject) {
    try {
      const year = new Date(dateObject).getFullYear();
      return !isNaN(year);
    } catch (ex) {
      return false;
    }
  },

  getJobVat(jobData) {
    const { COLLECTIONS, DELIVERIES, PRICE, collections, deliveries, price } =
      jobData;

    let nonUK = [];
    if (COLLECTIONS && DELIVERIES) {
      nonUK = COLLECTIONS.filter(({ COUNTRY_ID }) => COUNTRY_ID !== 12).concat(
        DELIVERIES.filter(({ _COUNTRY_ID }) => _COUNTRY_ID !== 12)
      );
    }

    if (collections && deliveries) {
      nonUK = collections
        .filter(({ countryId }) => countryId !== 12)
        .concat(deliveries.filter(({ countryId }) => countryId !== 12));
    }

    const jobPrice = PRICE == undefined ? price : PRICE;

    return nonUK.length ? 0.0 : jobPrice * 0.2;
  },

  getCountry(countryId) {
    return axios
      .get(`${server.serverCustomer}country/${countryId}`)
      .then((res) => res.data.data)
      .catch((err) => console.error(err))
      .then((country) => {
        return country[0];
      });
  },

  buildCurrenciesFilterDropdown(selectedCurrencyId, onChangeFunction) {
    // MULTIPLE USAGES AROUND THE APP SO THAT TO GENERATE A DROPDOWN OF AVAILABLE CURRENCIES TO CONVERT TABLE DATA TO
    setTimeout(() => {
      document
        .querySelectorAll("#currency-conversion-select")
        .forEach((el) => el.parentElement.remove()); // remove other conversion selects
      const sideBarRightEl = document.querySelector(
        ".tab-pane.active .js-ag-grid-action-bar-right"
      );

      // ADD THE DROPDOWN TO THE DOM AFTER ALL OTHER ELEMENTS
      sideBarRightEl.insertAdjacentHTML(
        "beforeend",
        `<div class="input-base js-tail-select">
        <select id="currency-conversion-select" name='currency-conversion-select' class="custom-select"></select>
        <label class="shrink">Currency Conversion</label>
      </div>`
      );

      // BUILD THE DROPDOWN
      axios
        .get(
          `${
            server.serverAdministration
          }locale/getLocaleAndBase/${localStorage.getItem("locale")}`
        )
        .then((response) => {
          const options = response.data.data.map(
            (currencyObj) =>
              `<option value="${currencyObj.CURRENCY_ID}" ${
                Number(selectedCurrencyId) === currencyObj.CURRENCY_ID
                  ? "selected"
                  : ""
              }>${currencyObj.CURRENCY_NAME} (${
                currencyObj.ISO_CURRENCY_CODE
              })</option>`
          );
          document.querySelector(
            "#currency-conversion-select"
          ).innerHTML = `<option value="default" selected>Default (Mixed Currencies)</option>${options}`;
          tailSelect(document.querySelector("#currency-conversion-select"), {
            placeholder: "Choose a currency",
            search: true,
            multiSelectAll: false
          });
        });

      document
        .querySelector("#currency-conversion-select")
        .addEventListener("change", (e) => onChangeFunction(e));
      // timeout offers a small buffer of time to allow all the grid to render (onGridReady may be called before full render). Also offers some UX purpose as not to display the dropdown too early.
    }, 1000);
  },
  applyCyTags(cyMap) {
    Object.entries(cyMap).forEach(([key, value]) => {
      jQuery(value).attr("data-cy", key);
    });
  },

  dropDownMenuFix({ pageInstance } = {}) {
    // THIS WILL ENSURE THAT FOR TAIL SELECTS THAT WE DISPLAY THE OPTIONS ON TOP OF ALL OTHER CONTENT VS MAKING MODALS ETC BIGGER IN HEIGHT
    let $dropdownMenu;

    jQuery(window).on("show.bs.dropdown", (e) => {
      // grab the menu
      const $dd = jQuery(e.target);

      $dropdownMenu = $dd.find(".dropdown-menu");

      if (
        !$dropdownMenu.closest(".js-grid-table").length &&
        !$dropdownMenu.hasClass("grid__dropdown-menu")
      ) {
        return;
      }

      // detach it and append it to the body
      jQuery("body").append($dropdownMenu.detach());

      const dropdownMenuElement = $dropdownMenu[0];
      if (!dropdownMenuElement?.classList.contains("generated")) {
        if (pageInstance) {
          if (typeof pageInstance.actionButtonsHandler === "function") {
            // Set action buttons handler
            pageInstance.actionButtonsHandler(dropdownMenuElement);
          }
        }
      }
      dropdownMenuElement?.classList.add("generated");
    });

    // and when you hide it, reattach the drop down, and hide it normally
    jQuery(window).on("hide.bs.dropdown", (e) => {
      if (!jQuery(e.target).closest(".js-grid-table").length) return;

      const $dd = jQuery(e.target);
      $dropdownMenu = $dd.find(".dropdown-menu");

      if ($dropdownMenu) {
        jQuery(e.target).append($dropdownMenu.detach());
        $dropdownMenu.hide();
      }
    });
  }
};

export default helpers;
