const datepicker = {
  datepickerInstance: null,
  startDate: "",
  endDate: "",

  datepickerCallback(startDate, endDate) {
    datepicker.startDate = startDate.format("YYYY-MM-DD");
    datepicker.endDate = endDate.format("YYYY-MM-DD");
  },

  updateRendereredCalendar(ele) {
    //change the selected date range of that picker
    $(ele).data("daterangepicker").setStartDate(ele.value);
    $(ele).data("daterangepicker").setEndDate(ele.value);
  }
};

export default datepicker;
