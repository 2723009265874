import lscache from "lscache";

let flushExpiredFrame = 0;

class LocalCache {
  constructor(prefix, ttl = 5) {
    this.prefix = prefix;
    this.ttl = ttl;
  }

  set(key, value, ttl = this.ttl) {
    lscache.set(`${this.prefix}-${key}`, value, ttl);
  }

  get(key) {
    return lscache.get(`${this.prefix}-${key}`);
  }

  remove(key) {
    lscache.remove(`${this.prefix}-${key}`);
  }

  flushExpired() {
    // Batch flush expired to a single call within a frame
    cancelAnimationFrame(flushExpiredFrame);
    flushExpiredFrame = requestAnimationFrame(() => lscache.flushExpired());
  }

  generateKey(...args) {
    return args
      .map((r) => (typeof r === "string" ? r : JSON.stringify(r)))
      .filter(Boolean)
      .join("-");
  }
}

function generatePromise(reqConfig) {
  let resolve;
  let reject;
  return {
    promise: new Promise((r, e) => {
      resolve = r;
      reject = e;
    }),
    resolve,
    reject,
    details: {
      request: reqConfig
    }
  };
}

export function applyCacheInterceptor(axiosInstance, { prefix, ttl = 5 }) {
  const cache = new LocalCache(prefix, ttl);
  const promiseCache = new Map();

  const getKey = (config) =>
    cache.generateKey(config.baseURL, config.url, config.data, config.params);

  const isCacheDisabled = (config) => {
    return Boolean(config.noCache);
  };

  axiosInstance.__cache = cache;

  axiosInstance.interceptors.request.use(async (reqConfig) => {
    const key = getKey(reqConfig);

    if (promiseCache.has(key))
      reqConfig.adapter = () => promiseCache.get(key).promise;
    else promiseCache.set(key, generatePromise(reqConfig));

    if (isCacheDisabled(reqConfig)) return reqConfig;

    cache.flushExpired();
    const cachedValue = cache.get(key);
    if (cachedValue) {
      // response interceptor won't run, cleanup promises and request count
      reqConfig.adapter = (config) => {
        const response = {
          __cached: true,
          ...cachedValue,
          config,
          request: reqConfig
        };
        promiseCache.get(key)?.resolve(response);
        promiseCache.delete(key);
        return Promise.resolve(response);
      };
    }

    return reqConfig;
  });

  axiosInstance.interceptors.response.use(
    async (response) => {
      const key = getKey(response.config);

      const pDetails = promiseCache.get(key);
      if (pDetails) {
        pDetails.resolve({
          ...pDetails.details,
          ...response
        });
        promiseCache.delete(key);
      }

      if (isCacheDisabled(response.config)) return response;

      if (!response.__cached)
        cache.set(key, response, response.config.cacheTtl);

      return Object.assign({}, response, {
        clearCache: () => cache.remove(key)
      });
    },
    (error) => {
      const key = getKey(error.config);

      const pDetails = promiseCache.get(key);
      if (pDetails) {
        pDetails.reject(error);
        promiseCache.delete(key);
        return pDetails.promise;
      }
      return Promise.reject(error);
    }
  );
}
