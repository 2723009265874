import { isValid } from "date-fns";
import { format, zonedTimeToUtc, utcToZonedTime, toDate } from "date-fns-tz";

const user = JSON.parse(sessionStorage.getItem("user"));

const USER_TIME_ZONE = user?.timezone?.zoneName || "Europe/London";
const DEFAULT_DATE_FORMAT = "yyyy-MM-dd HH:mm:ss";

export const extractMomentDate = (date) =>
  moment.isMoment(date) ? date.toDate() : toDate(date);

export const validateDate = (date) => {
  if (!isValid(date)) throw new Error("Invalid date");
};

/**
 * Wrap a callback function with a try/catch to return null
 * when an error occurs. Works with sync functions only, Promises
 * or async functions won't be caught.
 *
 * @template T
 * @param {T} cb The callback to wrap with
 * @returns {T}
 */
export const withNullOnError =
  (cb) =>
  (...args) => {
    try {
      return cb(...args);
    } catch (e) {
      // console.warn(e);
      return null;
    }
  };

/**
 * Convert a date from the given timezone to a UTC date. Timezone
 * defaults to user's time zone.
 *
 * @param {Date | string | number} date The date to convert
 * @param {string} timeZone The timezone to convert from
 * @returns {Date | null} The UTC date
 */
export const convertDateToUtc = withNullOnError(
  (date, timeZone = USER_TIME_ZONE) => {
    const dateObj = extractMomentDate(date);
    validateDate(dateObj);
    return zonedTimeToUtc(dateObj, timeZone);
  }
);

/**
 * Convert a date from UTC to the given timezone. Timezone
 * defaults to user's time zone.
 *
 * @param {Date | string | number} date The date to convert
 * @param {string} timeZone The timezone to convert to
 * @returns {Date | null} The date zoned to user's timezone
 */
export const convertUtcToZoned = withNullOnError(
  (date, timeZone = USER_TIME_ZONE) => {
    const dateObj = extractMomentDate(date);
    validateDate(dateObj);
    return utcToZonedTime(dateObj, timeZone);
  }
);

/**
 * Formats the date for attaching it into a payload.
 * This should be used when dates are stored without time.
 *
 * @param {Date | number} date The date to format
 * @returns {string | null} The date in a yyyy-MM-dd format
 */
export const formatToDatePayload = withNullOnError((date) => {
  const dateObj = extractMomentDate(date);
  validateDate(dateObj);
  return format(dateObj, "yyyy-MM-dd");
});

/**
 * Formats the date for attaching it into a payload.
 * This should be used when dates are stored with time.
 *
 * @param {Date | number} date The date to format
 * @param {string} dateformat The desired date format
 * @returns {string | null} The date in a yyyy-MM-dd hh:mm:ss format or date format passed in
 */
export const formatToDateTimePayload = withNullOnError(
  (date, dateformat = DEFAULT_DATE_FORMAT) => {
    const dateObj = extractMomentDate(date);
    validateDate(dateObj);
    return format(dateObj, dateformat);
  }
);

/**
 * Remove the hours from date object to have clean date.
 * Mutates the date object passed.
 *
 * @param {Date} [date] The date to update the hours.
 */
export const removeDateHours = withNullOnError((date) => {
  validateDate(date);
  date.setHours(0, 0, 0, 0);
});

/**
 * Remove the time from an ISO date string.
 *
 * @param {string} [dateString]
 */
export const removeTime = (dateString) => {
  // Matches:
  // ISO date 2023-09-05T10:39:18.481Z
  // Date with spaces 2023-09-05 10:39:18
  if (typeof dateString === "string")
    return dateString.replace(/T.*Z| \d{2}:\d{2}:\d{2}/g, "");
  if (typeof date === "object") return formatToDatePayload(dateString);
  return dateString;
};

/**
 * Remove the T and Z from an ISO date string.
 * This would help into parsing a date as is without
 * taking into consideration UTC which would produce
 * different results.
 *
 * @param {string} [dateString]
 */
export const removeTZ = (dateString) => {
  if (typeof dateString === "string")
    return dateString.replace(/T|Z$|\.\d+Z/g, " ").trim();
  return dateString;
};

/**
 * Concat date and time to a date object.
 *
 * @param {string} date The date in ISO representation YYYY-MM-DD
 * @param {string} time The time with `:` delimiters. Eg 11:22:59
 * @param {string} timezone The date-time timezone
 */
export const concatDateTime = (date, time, timezone) => {
  const dateString =
    typeof date === "string" ? date : formatToDatePayload(date);
  const dateNoTime = removeTime(dateString);
  return convertDateToUtc(`${dateNoTime} ${time}`, timezone);
};
