const registeredEvents = new Set();

class BaseEvent {
  constructor(namespace, name) {
    const eventName = `${namespace}::${name}`;
    if (registeredEvents.has(eventName))
      throw new Error(`[BaseEvent]: Event "${eventName}" is already created!`);
    registeredEvents.add(eventName);
    this.name = eventName;
  }

  /**
   * @param {Node} el
   * @param {Object} [payload] The payload to send
   */
  dispatch = (el, payload) => {
    const event = new CustomEvent(this.name, {
      detail: payload,
      bubbles: true
    });
    el.dispatchEvent(event);
  };

  /**
   * @param {Node} el
   * @param {(event: CustomEvent) => void} cb
   * @returns {() => void} Handler to remove listener
   */
  listen = (el, cb) => {
    const handler = (event) => cb(event);
    el.addEventListener(this.name, handler);
    return () => el.removeEventListener(this.name, handler);
  };
}

export default function createEvent(namespace, name) {
  return new BaseEvent(namespace, name);
}
