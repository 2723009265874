import { formatDate, formatDateTimeOnly, parseDate } from "./date-formatters";

const datetimepicker = {
  formatDate(date, dateOnly) {
    if (!date) return null;
    const dateObj = parseDate(date);
    if (dateOnly) return formatDate(dateObj);
    return formatDateTimeOnly(dateObj);
  },

  formatTime(time, seconds) {
    if (time) {
      time = time.split(":");

      if (seconds) {
        return `${time[0]}:${time[1]}:${time[2]}`;
      } else {
        return `${time[0]}:${time[1]}`;
      }
    } else {
      return null;
    }
  },

  removeTimezoneFromDateString(date) {
    if (!date) {
      return false;
    }

    return date.replace(/T.*Z/, "");
  }
};

export default datetimepicker;
