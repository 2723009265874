// Utility functions for interacting with date range picker elements
import { differenceInCalendarDays } from "date-fns";
import {
  formatDatePattern,
  formatDateToISO,
  parseDate
} from "./date-formatters";
import { formatToDatePayload, formatToDateTimePayload } from "./dates";

const type = /** @type {const} */ ({
  start: "start",
  end: "end"
});

/**
 * @param {HTMLElement} el
 * @returns {import('daterangepicker')}
 */
function getInstance(el) {
  return $(el).data("daterangepicker");
}

/**
 * @param {HTMLElement} el The element that date range picker is initialized
 * @param {type[keyof type]} [dateType] If start or end date should be retrieved
 */
function getDate(el, dateType = type.start) {
  const drp = getInstance(el);
  return drp[`${dateType}Date`];
}

/**
 * @param {HTMLElement} el The element that date range picker is initialized
 * @param {type[keyof type]} [dateType] If start or end date should be retrieved
 */
function getDatePayload(el, dateType) {
  return formatToDatePayload(getDate(el, dateType));
}

/**
 * @param {HTMLElement} el The element that date range picker is initialized
 * @param {type[keyof type]} [dateType] If start or end date should be retrieved
 */
function getDateTimePayload(el, dateType) {
  return formatToDateTimePayload(getDate(el, dateType));
}

/**
 * @param {HTMLElement} el The element that date range picker is initialized
 * @param {type[keyof type]} [dateType] If start or end date should be retrieved
 */
function getDateISO(el, dateType) {
  return formatDateToISO(getDate(el, dateType));
}

function setDate(el, startDate, endDate) {
  try {
    const parsedStart = parseDateWithError(startDate);
    const parsedEnd = parseDateWithError(endDate ?? startDate);
    const drp = getInstance(el);
    drp.setStartDate(parsedStart);
    drp.setEndDate(parsedEnd);
    const formattedStart = formatDatePattern(parsedStart, drp.locale.format);
    if (endDate) {
      $(el).val(
        `${formattedStart} - ${formatDatePattern(parsedEnd, drp.locale.format)}`
      );
    } else {
      $(el).val(formattedStart);
    }
  } catch (error) {
    console.warn(error);
  }
}

function parseDateWithError(date) {
  const parsedDate = parseDate(date);
  if (!parseDate) {
    throw new Error(`Date couldn't be parsed: ${date}`);
  }
  return parsedDate;
}

/**
 * @param {HTMLInputElement} el
 * @returns If the element's input is after date provided. Defaults to today's date.
 */
function isDateAfter(el, date = new Date()) {
  const drp = getInstance(el);

  let parsedDate = parseDate(drp?.startDate);

  if (!drp) {
    parsedDate = parseDate(el.value);
  }

  if (!parsedDate) return false;

  return differenceInCalendarDays(parsedDate, date) > 0;
}

export default {
  type,
  getInstance,
  getDate,
  getDatePayload,
  getDateTimePayload,
  getDateISO,
  setDate,
  isDateAfter
};
