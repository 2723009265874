import { applyCacheInterceptor } from "../helpers/applyCacheInterceptor";

/**
 * @param {string} baseUrl The base url of the client
 * @param {{ prefix: string, ttl?: number }} [cacheOptions] Cache options if caching needs to be applied. Ttl is in minutes.
 */
export const getHttpClient = (baseUrl, cacheOptions) => {
  /** @type {import('axios').AxiosInstance} */
  const client = axios.create({
    baseURL: baseUrl
  });

  for (const type in axios.interceptors) {
    axios.interceptors[type].handlers.forEach(({ fulfilled, rejected }) =>
      client.interceptors[type].use(fulfilled, rejected)
    );
  }

  if (cacheOptions) applyCacheInterceptor(client, cacheOptions);

  return client;
};
