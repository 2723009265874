import { getHttpClient } from "./HttpClient";

const httpClients = new WeakMap();

export default class BaseService {
  /**
   * @protected
   * @abstract
   */
  baseUrl = "";

  /**
   * @protected
   * @type {{ prefix: string, ttl?: number }} Cache options if caching needs to be applied. Ttl is in minutes.
   */
  cacheOptions = undefined;

  /**
   * @protected
   * @type {import('axios').AxiosInstance}
   */
  get httpClient() {
    if (httpClients.has(this)) return httpClients.get(this);
    httpClients.set(this, getHttpClient(this.baseUrl, this.cacheOptions));
    return httpClients.get(this);
  }
}
