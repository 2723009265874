export const defaultSettings = Object.freeze({
  companyName: "Speedy Freight"
});

export const commentTypeId = Object.freeze({
  supplierStatement: 1,
  job: 2,
  account: 3,
  default: 0
});

export const defaultBillingData = Object.freeze({
  invoiceTypeId: 9,
  invoiceScheduleId: 1,
  paymentTermId: 1,
  factoringDdTypeId: 1
});

export const fractionDigits = Object.freeze({
  minimum: 2,
  maximum: 2
});

export const defaultGoodsData = Object.freeze({
  quantity: 1,
  description: "Goods",
  weight: 50,
  length: 0,
  height: 0,
  width: 0,
  consolidatedSwitch: false
});

export const contactTypeId = Object.freeze({
  PRIMARY: 1,
  SECONDARY: 2,
  BILLING: 3,
  OTHER: 4,
  SUPPLIER: 6,
  COLLECTION: 7,
  DELIVERY: 8,
  ACCOUNTS_PAYABLE: 9,
  STATEMENT: 10
});

export const accountTypeId = Object.freeze({
  CASH: 1,
  CREDIT: 2
});

export const paymentTypeId = Object.freeze({
  PORTAL: 1,
  CHEQUE: 2,
  BACS: 3,
  STRIPE_LINK: 4
});

export const stripePaymentStatusId = Object.freeze({
  SENT: { id: 189, text: "Sent" },
  PENDING: { id: 190, text: "Pending" },
  PAID: { id: 191, text: "Paid" },
  UNPAID: { id: 192, text: "Unpaid" }
});

export const deletedId = Object.freeze({
  DELETED: 21
});

export const isPreferredIndId = Object.freeze({
  IS_PREFERRED: 1,
  IS_NOT_PREFERRED: 0
});

export const indicatorStatusId = Object.freeze({
  YES: 1,
  NO: 0
});

export const addressTypeId = Object.freeze({
  PRIMARY: 1,
  BILLING: 2,
  COLLECTION: 3,
  DELIVERY: 4,
  SECONDARY: 5,
  TRADING: 6,
  REGISTERED_OFFICE: 7,
  BOOKER: 8,
  STATEMENT: 9
});

export const nationalCustomerIndId = Object.freeze({
  YES: 1,
  NO: 0,
  BOTH: 2
});

export const glCodeId = Object.freeze({
  CORE_ACCOUNT: {
    ID: 1,
    CODE: 40000,
    TYPE: "CUSTOMER"
  },
  NATIONAL_ACCOUNT: {
    ID: 2,
    CODE: 40010,
    TYPE: "CUSTOMER"
  },
  DRIVER_COST: {
    ID: 3,
    CODE: 60020,
    TYPE: "SUPPLIER"
  }
});

export const notificationStatusId = Object.freeze({
  UNREAD: 78,
  READ: 79,
  DELETED: 80
});

export const supplierStatusId = Object.freeze({
  AVAILABLE: 70,
  REQUESTED: 71,
  REJECTED: 72,
  EXPIRING: 73,
  EXPIRED: 74,
  IN_PROGRESS: 76,
  INACTIVE: 77
});

export const accountStatusId = Object.freeze({
  ACTIVE: 10,
  DORMANT: 11,
  ON_HOLD: 12,
  PREMIUM: 13,
  MONITORED: 14,
  QUOTE: 15,
  LOST: 16,
  CLOSED: 17,
  PENDING: 176,
  NEW: 177,
  FLIPPED: 178
});

export const accountClassificationId = Object.freeze({
  PRIVATE: 1,
  LTD: 2,
  SOLE_TRADER: 3,
  LLP: 4,
  "S-CORP": 5,
  "C-CORP": 6,
  LLC: 7
});

export const businessAreaTypeId = Object.freeze({
  DEFAULT: 1
});

export const cardPaymentIndId = Object.freeze({
  YES: 1,
  NO: 0
});

export const manualCostIndId = Object.freeze({
  YES: 1,
  NO: 0
});

export const currencyTypeId = Object.freeze({
  POUND: 1,
  DOLLAR: 2,
  EURO: 3
});

export const taxRateType = Object.freeze({
  YES: {
    id: 1,
    description: "Yes"
  },
  NO: {
    id: 0,
    description: "No"
  }
});

export const taxRateTypeId = Object.freeze({
  VAT_20: 1,
  VAT_0: 2,
  VAT_5: 3,
  VAT_10: 4,
  VAT_15: 5,
  VAT_25: 6,
  VAT_21: 7
});

export const countryTypeId = Object.freeze({
  SPAIN: 9,
  GB: 12,
  US: 231
});

export const jobDeliveryStatusTypeId = Object.freeze({
  ACTIVE: 34,
  INACTIVE: 35
});

export const jobStatusTypeId = Object.freeze({
  UNALLOCATED: 39,
  COMPLETED: 40,
  CANCELLED: 41,
  ARRIVED_FOR_COLLECTION: 42,
  GOODS_COLLECTED: 43,
  ARRIVED_FOR_DELIVERY: 44,
  GOODS_DELIVERED: 45,
  POD_RECEIVED: 46,
  INVOICED: 47,
  SUPPLIER_DISPUTE: 49,
  QUOTE_LOST: 51,
  PRE_PENDING_QUOTE: 52,
  PENDING_QUOTE: 53,
  TRANSPORT_REVIEW: 54,
  ALLOCATED: 55,
  PENDING_JOB: 56,
  PO_REQUIRED: 57,
  ON_ROUTE_TO_COLLECTION: 59,
  ON_ROUTE_TO_DELIVERY: 60,
  READY_TO_INVOICE: 61,
  IN_DISPUTE_INVOICE: 62,
  INVOICE_IN_PROGRESS: 63,
  IN_DISPUTE: 65,
  RUNNING_LATE: 66,
  QUOTE_REQUESTED: 175,
  QUOTE_EXPIRED: 184,
  CUSTOMER_DISPUTE: 48,
  JOB_DISPUTE: 64,
  POD_REQUIRED: 195
});

export const iconStatusTypes = Object.freeze({
  jobUnknownStatusTypes: {
    QUOTE_WON: 36,
    JOB_CONFIRMED: 37,
    ALLOCATED: 38,
    JOB_BOOKING_ACTIVE: 50,
    QUOTE_ONLY_JOB: 58
  },
  jobStatusTypes: {
    ...jobStatusTypeId
  },
  jobDeliveryStatusTypes: {
    ...jobDeliveryStatusTypeId
  }
});

export const reviewStatusTypeId = Object.freeze({
  IN_REVIEW: {
    id: 193,
    text: "In Review"
  },
  REVIEWED: {
    id: 194,
    text: "Reviewed"
  }
});

export const invoiceTypeId = Object.freeze({
  MONTHLY: 2,
  ARGOS: 3,
  SUB_PO_INVOICING: 3,
  PRE_ADVICE: 8,
  ON_DAY: 9,
  SPAIN: 10,
  PURCHASE_ORDER: 11
});

export const skeletonClosetId = Object.freeze({
  QTY: 2
});

export const billingStoreId = Object.freeze({
  PARENT: 1,
  SAME_AS_ACCOUNT: 2,
  OTHER: 3
});

export const invoiceStatus = Object.freeze({
  CREATED: {
    ID: 82,
    COLOUR: "green",
    TEXT: "Created"
  },
  SENT: {
    ID: 83,
    COLOUR: "green",
    TEXT: "Sent"
  },
  AWAITING_APPROVAL: {
    ID: 86,
    COLOUR: "locked",
    TEXT: "Awaiting Approval"
  },
  NOT_YET_DUE: {
    ID: 180,
    COLOUR: "blue",
    TEXT: "Not Yet Due"
  },
  PARTIALLY_PAID: {
    ID: 181,
    COLOUR: "yellow",
    TEXT: "Partially Paid"
  },
  OVERDUE: {
    ID: 182,
    COLOUR: "locked",
    TEXT: "Overdue"
  },
  PAID: {
    ID: 183,
    COLOUR: "green",
    TEXT: "Paid"
  }
});

export const unitOfMeasureTypeId = Object.freeze({
  DISTANCE: 1,
  WEIGHT: 2,
  DIMENSION: 3,
  VOLUME: 4
});

export const unitOfMeasureId = Object.freeze({
  DISTANCE: {
    MILES: 1,
    KILOMETRES: 2
  },
  WEIGHT: {
    KILOGRAMS: 3,
    POUNDS: 4
  },
  DIMENSION: {
    CENTIMETRES: 5,
    INCHES: 6
  },
  VOLUME: {
    CUBIC_METRES: 7,
    CUBIC_YARDS: 8
  }
});

export const defaultUnitOfMeasure = Object.freeze({
  MILES: {
    uomShortHand: "mi",
    uomDescription: "Miles"
  },
  KILOGRAMS: {
    uomShortHand: "kg",
    uomDescription: "Kilograms"
  },
  CENTIMETRES: {
    uomShortHand: "cm",
    uomDescription: "Centimetres"
  },
  CUBIC_METRES: {
    uomShortHand: "m³",
    uomDescription: "Cubic Metres"
  }
});

export const usUnitOfMeasure = Object.freeze({
  KILOMETRES: {
    uomShortHand: "km",
    uomDescription: "Kilometres"
  },
  POUNDS: {
    uomShortHand: "lb",
    uomDescription: "Pounds"
  },
  INCHES: {
    uomShortHand: "in",
    uomDescription: "Inches"
  },
  CUBIC_YARDS: {
    uomShortHand: "yd³",
    uomDescription: "Cubic Yards"
  }
});

export const totalUomFieldTypes = Object.freeze({
  TOTAL_MILEAGE: "TOTAL_MILEAGE",
  TOTAL_KM: "TOTAL_KM"
});

export const timeOptionId = Object.freeze({
  fixedTime: 1,
  asap: 2,
  am: 3,
  pm: 4,
  timeRange: 5,
  allDay: 6,
  dateRange: 7
});

export const DBBoolean = Object.freeze({
  TRUE: 1,
  FALSE: 0
});

export const statementStatuses = Object.freeze({
  OUTSTANDING: 132,
  INVOICE_DISCREPANCY: 133,
  POD_DISCREPANCY: 134,
  ADDITIONS: 135,
  SPECIAL_PAYMENT: 136,
  SAME_DAY_PAYMENT: 137,
  UPDATED: 138,
  MOVED_TO_MONTHLY: 139,
  FULL_PAYMENT_DUE: 140,
  PART_PAYMENT_DUE: 141,
  DRIVER_PART_PAID: 142,
  DRIVER_PAID: 143,
  STATEMENT_GENERATED: 144,
  PENDING_AUTHORISATION: 145
});

export const supplierInvoiceStatuses = Object.freeze({
  IN_DISPUTE: 187
});

export const supplierInvoiceTypes = Object.freeze({
  STATEMENT: 1,
  OVERHEAD: 2
});

export const SupplierTypes = Object.freeze({
  CX: 1,
  NON_CX: 2,
  FRANCHISEE: 3,
  DRIVERS_WEEKLY: 4,
  DRIVERS_MONTHLY: 5,
  EMPLOYEE: 6,
  T24: 7
});

export const errorMessages = Object.freeze({
  PRIMARY_ADDRESS:
    "A Primary Address must exist. Please save a primary address before adding an alternative address type.",
  PRIMARY_ADDRESS_FLIPPED:
    "A Primary Address must exist. Please save a primary address before changing the account status to Flipped.",
  BILLING_ADDRESS_FLIPPED:
    "A Billing Address must exist. Please save a billing address before changing the account status to Flipped."
});

export const cssColors = Object.freeze({
  pinkBlue: "color--gradient-pink-blue",
  orangePink: "color--gradient-orange-pink",
  cyanBlue: "color--gradient-cyan-blue",
  bluePurple: "color--gradient-blue-purple",
  greenYellow: "color--gradient-green-yellow",
  blue: "color--blue",
  white: "color--white"
});

export const typeIndexId = Object.freeze({
  YES: 1,
  NO: 0
});

export const schedulesTypeId = Object.freeze({
  DAILY: 1,
  WEEKLY: 2,
  MONTHLY: 3
});

export const splitMovementTypeId = Object.freeze({
  COMPLETED: 120,
  IN_PROGRESS: 121,
  POD_UPLOAD_NEEDED: 122,
  DELETED: 123
});

export const additionalAmountTypes = Object.freeze({
  COST: {
    ID: 1,
    TITLE: "Cost"
  },
  CHARGE: {
    ID: 2,
    TITLE: "Charge"
  }
});

export const additionalAmountReasonTypes = Object.freeze({
  ACCESSORY: 5
});

export const accountContactStatusId = Object.freeze({
  active: 28,
  inactive: 29
});

export const ContactAddressStatusId = Object.freeze({
  Active: 30,
  Inactive: 31
});

export const jobImportStatuses = Object.freeze({
  VALIDATING: "Validating",
  PROCESSING: "Processing",
  ERROR: "Error",
  IMPORTING: "Importing",
  COMPLETE: "Completed",
  PENDING: "Pending"
});

export const accountModuleTypes = Object.freeze({
  PARCELS: 1,
  FULFILL: 2,
  FREIGHT: 3
});

export const payloadTypes = Object.freeze({
  DELETE_ADDITIONAL_AMOUNTS: "DELETE_ADDITIONAL_AMOUNTS"
});

export const timeCheckTypes = Object.freeze({
  FIXED_TIME: {
    ID: 1,
    TEXT: "Fixed Time"
  },
  ASAP: {
    ID: 2,
    TEXT: "ASAP"
  },
  AM: {
    ID: 3,
    TEXT: "AM"
  },
  PM: {
    ID: 4,
    TEXT: "PM"
  },
  TIME_RANGE: {
    ID: 5,
    TEXT: "Time Range"
  },
  ALL_DAY: {
    ID: 6,
    TEXT: "All Day"
  },
  DATE_RANGE: {
    ID: 7,
    TEXT: "Date Range"
  }
});

export const localeTypeId = Object.freeze({
  GB: 1,
  ES: 2,
  US: 3
});

export const supplierOriginSourceTypeId = Object.freeze({
  TMS: 1,
  CX: 2,
  MY_CARRIER: 3
});

export const blockedStatusTypeId = Object.freeze({
  YES: { booleanInd: 1, value: "Yes", text: "Blocked" },
  NO: { booleanInd: 0, value: "No", text: "Active" }
});

export const menuCYMap = Object.freeze({
  Home: "SidePanel-Home",
  TMS: "SidePanel-TMS",
  Operations: "SidePanel-TMS-Operations",
  "New Job": "SidePanel-TMS-Operations-NewJob",
  "Job Board": "SidePanel-TMS-Operations-JobBoard",
  Templates: "SidePanel-TMS-Operations-Templates",
  "Traffic Board": "SidePanel-TMS-Operations-TrafficBoard",
  Schedules: "SidePanel-TMS-Operations-Schedules",
  "Job Import": "SidePanel-TMS-Operations-JobImport",
  "Conversion Dashboard": "SidePanel-TMS-Operations-ConversionDashboard",
  Accounts: "SidePanel-TMS-Accounts",
  "Create Account": "SidePanel-TMS-Accounts-CreateAccount",
  Customers: "SidePanel-TMS-Accounts-Customers",
  "Invoices and Credit Notes": "SidePanel-TMS-Accounts-InvoicesAndCreditNotes",
  "Sage Logs": "SidePanel-TMS-Accounts-SageLogs",
  Suppliers: "SidePanel-TMS-Suppliers",
  "New Supplier": "SidePanel-TMS-Suppliers-NewSupplier",
  "Supplier Board": "SidePanel-TMS-SuppliersSupplierBoard",
  "Supplier Statements": "SidePanel-TMS-Suppliers-SupplierStatements",
  "Commission Statement": "SidePanel-TMS-CommissionStatement",
  "Commission Statement Management":
    "SidePanel-TMS-CommissionStatement-CommissionStatementManagement",
  Analysis: "SidePanel-TMS-Analysis",
  Reporting: "SidePanel-TMS-Analysis-Reporting",
  Logs: "SidePanel-TMS-Logs",
  "Business Central": "SidePanel-TMS-Logs-BusinessCentral",
  "Avalara Logs": "SidePanel-TMS-Logs-AvalaraLogs",
  Administration: "SidePanel-Administration",
  "Branch Management": "SidePanel-Administration-BranchManagement",
  "User Management": "SidePanel-Administration-UserManagement",
  "Email Management": "SidePanel-Administration-EmailManagement",
  Settings: "SidePanel-Settings"
});

export const globalCyMap = Object.freeze({
  "Header-Home": ".navbar-brand a",
  "Header-CreateJob": ".navbar-brand .js-create-new-job-btn",
  "Header-SidePanel": ".navbar .js-slideout-menu",
  "Header-Notifications": ".navbar .js-notification-icon",
  "Header-SearchCriteria": ".navbar .js-global-search-bar-input",
  "Header-SearchType": "#globalSearchType + div.tail-select",
  "SearchType-JobSearch": ".global-search-bar  li[data-key='job']",
  "SearchType-InvoiceSearch": ".global-search-bar  li[data-key='invoice']",
  "SearchType-AccountSearch": ".global-search-bar  li[data-key='account']",
  "SearchType-SupplierSearch": ".global-search-bar  li[data-key='supplier']",
  "SearchType-SupplierStatement":
    ".global-search-bar  li[data-key='supplierStatement']",
  "SearchType-SupplierInvoice":
    ".global-search-bar  li[data-key='SupplierInvoice']"
});

export const menuProfileCyMap = Object.freeze({
  "SidePanel-Profile-User": ".user-options .js-user-name",
  "SidePanel-Profile-AccountSettings":
    ".user-options .js-user-account-settings",
  "SidePanel-Profile-Tooltips": ".user-options .js-user-tooltip-toggle",
  "SidePanel-Profile-Logout": ".user-options .js-user-logout"
});

export const navCardCYMap = Object.freeze({
  TMS: "Dashboard-TMS",
  Administration: "Dashboard-Administration",
  Settings: "Dashboard-Settings"
});

export const createAccountCyMap = Object.freeze({
  "payment-card-payment":
    'div.input-base:has(select[name="cardPaymentInd"]) button.dropdown-toggle',
  "payment-credit-amount": 'input[name="creditLimit"]',
  "payment-vat-Number": 'input[name="vatRegistrationNo"]',
  "payment-tax-rate":
    'div.input-base:has(select[name="taxRateId"]) button.dropdown-toggle',
  "payment-tax-currency":
    'div.input-base:has(select[name="currencyId"]) button.dropdown-toggle',
  "payment-payment-type":
    'div.input-base:has(select[name="paymentTypeId"]) button.dropdown-toggle',
  "insurance-insurance-type":
    'div.input-base:has(select[name="insuranceTypeId"]) button.dropdown-toggle',
  "insurance-sic-code":
    'div.input-base:has(select[name="sicCode"]) button.dropdown-toggle',
  "insurance-credit-check": 'input[name="creditUpdateDate"]',
  "account-tms-code": 'input[name="accountAreaReference1"]',
  "account-account-type":
    'div.input-base:has(select[name="accountTypeId"]) button.dropdown-toggle',
  "account-general-ledger-code":
    'div.input-base:has(select[name="bcGlCodeId"]) button.dropdown-toggle',
  "account-account-link-to-hubspot":
    "button.js-link-existing-hubspot-account-button",
  "branch-branch": 'select[name="accountManager"] + div.tail-select',
  "create-account-save-continue": "#saveAccountDetailsBtn",
  "contacts-how-did-you-hear":
    'div.input-base:has(select[name="accountOriginatingSourceId"]) button.dropdown-toggle',
  "contacts-add-contact": "button.js-account-add-contact",
  "business-company-name": 'input[name="accountName"]',
  "business-account-classification":
    'div.input-base:has(select[name="accountClassificationId"]) button.dropdown-toggle',
  "business-industry-type":
    'div.input-base:has(select[name="industryTypeId"]) button.dropdown-toggle',
  "business-industry-sub-type":
    'div.input-base:has(select[name="industrySubTypeId"]) button.dropdown-toggle',
  "business-company-reg": 'input[name="companyRegNo"]',
  "business-eori-number": 'input[name="eoriNumber"]',
  "business-account-is-parent": "div.custom-switch:has(input#isParent)",
  "business-account-is-national":
    "div.custom-switch:has(input#nationalCustomerInd)",
  "business-bcc-trustpilot-email":
    "div.custom-switch:has(input#trustpilotEmailEnabledInd)",
  "address-add-address": 'button[data-target="#account-add-address"]',
  "Add-Address-Type":
    'div.modal#account-add-address  div.input-base:has(select[name="addressTypeId"]) button.dropdown-toggle',
  "Add-Address-Name": 'div.modal#account-add-address input[name="addressName"]',
  "Add-Address-Line1":
    'div.modal#account-add-address input[name="addressLine1"]',
  "Add-Address-Line2":
    'div.modal#account-add-address input[name="addressLine2"]',
  "Add-Address-City": 'div.modal#account-add-address input[name="city"]',
  "Add-Address-County": 'div.modal#account-add-address input[name="county"]',
  "Add-Address-Postcode":
    'div.modal#account-add-address input[name="postCode"]',
  "Add-Address-Close":
    'div.modal#account-add-address button.btn[data-dismiss="modal"]',
  "Add-Address-Reset": "div.modal#account-add-address button.js-address-reset",
  "Add-Address-Save": 'div.modal#account-add-address button[type="submit"]'
});

export const createAccountAddContactCyMap = Object.freeze({
  "Add-Contact-Type":
    'div.modal#account-contact div.input-base:has(select[name="contactTypeId"]) div.tail',
  "Add-Contact-FirstName": 'div.modal#account-contact input[name="firstName"]',
  "Add-Contact-LastName": 'div.modal#account-contact input[name="lastName"]',
  "Add-Contact-Email": 'div.modal#account-contact input[name="email"]',
  "Add-Contact-Primary-Number": 'div.modal#account-contact input[name="phone"]',
  "Add-Contact-Secondary-Number":
    'div.modal#account-contact input[name="mobile"]',
  "Add-Contact-Fax": 'div.modal#account-contact input[name="faxNo"]',
  "Add-Contact-Hubspot-ID":
    'div.modal#account-contact input[name="hubspotContactId"]',
  "Add-Contact-Address":
    'div.modal#account-contact  div.input-base:has(select[name="addressId]) button.dropdown-toggle',
  "Add-Contact-Hubspot-Contact":
    "div.modal#account-contact button.js-link-existing-hubspot-account",
  "Add-Contact-Booker-Address":
    "div.modal#account-contact button.js-open-account-detail",
  "Add-Contact-Cancel":
    'div.modal#account-contact button.btn[data-dismiss="modal"]',
  "Add-Contact-Save": 'div.modal#account-contact button[type="submit"]'
});

export const createSupplierCyMap = Object.freeze({
  "New-Supplier-Supplier-Ref": 'input[name="supplierReference1"]',
  "New-Supplier-Company-Ref": 'input[name="supplierName"]',
  "New-Supplier-Company-Reg-Number": 'input[name="companyRegistrationNo"]',
  "New-Supplier-Business-Type":
    'form#supplier-details-form div.input-base:has(select[name="supplierBusinessTypeId"]) button.dropdown-toggle',
  "New-Supplier-Sage-Code": 'input[name="sageReferenceId"]',
  "New-Supplier-DOT-Number": 'input[name="usDepartmentOfTransportNumber"]',
  "New-Supplier-MC-Number": 'input[name="usMCNumber"]',
  "New-Supplier-CX-Code": 'input[name="courierExchangeCode"]',
  "New-Supplier-VAT-Registered":
    'form#supplier-details-form div.input-base:has(select[name="chargeVatInd"]) button.dropdown-toggle',
  "New-Supplier-VAT-Rate":
    'form#supplier-details-form div.input-base:has(select[name="taxRateId"]) button.dropdown-toggle',
  "New-Supplier-VAT-Number": 'input[name="vatRegistrationNo"]',
  "New-Supplier-Add-Address": "form#supplier-details-form #addAddressBtn",
  "New-Supplier-Add-Contact": "form#supplier-details-form #addContactBtn",
  "New-Supplier-Own-Driver":
    'form#supplier-details-form div.custom-switch:has(input[name="ownDriverInd"])',
  "New-Supplier-Select-All-Vehicles":
    'form#supplier-details-form div.custom-switch:has(input[name="selectAllVehicles"])',
  "New-Supplier-Vehicle-Type":
    'form#supplier-details-form div.input-base:has(select[name="vehicleTypeId"]) button.dropdown-toggle',
  "New-Supplier-Handset-Type":
    'form#supplier-details-form div.input-base:has(select[name="handsetTypeId"]) button.dropdown-toggle',
  "New-Supplier-Navigation":
    'form#supplier-details-form div.input-base:has(select[name="applicationNavigationTypeId"]) button.dropdown-toggle',
  "New-Supplier-Currency":
    'form#supplier-details-form div.input-base:has(select[name="currencyId"]) button.dropdown-toggle',
  "New-Supplier-Account-Name": 'input[name="bankAccountName"]',
  "New-Supplier-Account-Number": 'input[name="bankAccountNo"]',
  "New-Supplier-Sort-Code": 'input[name="sortCode"]',
  "New-Supplier-BIC": 'input[name="bic"]',
  "New-Supplier-IBAN": 'input[name="iban"]',
  "New-Supplier-Routing-No": 'input[name="routingNo"]',
  "New-Supplier-Payment-Terms":
    'form#supplier-details-form div.input-base:has(select[name="paymentTermId"]) button.dropdown-toggle',
  "New-Supplier-Supplier-Type":
    'form#supplier-details-form div.input-base:has(select[name="supplierTypeId"]) button.dropdown-toggle',
  "New-Supplier-Branch":
    'form#supplier-details-form div.input-base:has(select[name="branch"]) button.dropdown-toggle',
  "New-Supplier-General-Ledger":
    'form#supplier-details-form div.input-base:has(select[name="bcGlCodeId"]) button.dropdown-toggle',
  "New-Supplier-Discard": "form#supplier-details-form #discardSupplierBtn",
  "New-Supplier-Continue": "form#supplier-details-form #continueBtn"
});

export const defaultLocaleTypes = Object.freeze({
  [localeTypeId.GB]: {
    taxRateId: taxRateTypeId.VAT_20,
    localeId: localeTypeId.GB,
    currencyId: currencyTypeId.POUND
  },
  [localeTypeId.ES]: {
    taxRateId: taxRateTypeId.VAT_21,
    localeId: localeTypeId.ES,
    currencyId: currencyTypeId.EURO
  },
  [localeTypeId.US]: {
    taxRateId: taxRateTypeId.VAT_0,
    localeId: localeTypeId.US,
    currencyId: currencyTypeId.DOLLAR
  }
});

export const defaultItemTypeValues = Object.freeze({
  PALLETS_UK: {
    itemTypeId: 4,
    length: 120,
    width: 100,
    height: 100
  },
  PALLETS_EURO: {
    itemTypeId: 11,
    length: 120,
    width: 80,
    height: 100
  },
  PALLETS_US: {
    itemTypeId: 15,
    length: 120,
    width: 100,
    height: 100
  },
  UNSPECIFIED: {
    itemTypeId: 14,
    length: 1,
    height: 1,
    width: 1
  }
});
